import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/home', title: 'Home', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/dashboard/users', title: 'Users', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/dashboard/artists', title: 'Artists', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // ft-user
  {
    path: '/dashboard/pollings', title: 'Pollings', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // {
    // path: '/dashboard/reportUser', title: 'Report User', icon: 'ft-align-left', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/dashboard/reportlist', title: 'Blocked Users', icon: 'ft-user-x', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/dashboard/reportpooling', title: 'Blocked Polls', icon: 'ft-slash', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/login', title: 'Logout', icon: 'ft-log-out', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  }
  //   {
  //     path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'has-sub', badge: '3', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
  //     submenu: [
  //         { path: '/YOUR-ROUTE-PATH', title: 'Second Level', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         {
  //             path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //             submenu: [
  //                 { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //                 { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //             ]
  //         },
  //     ]
  // },
];
